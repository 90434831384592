import React from "react";
import './App.css';
import sometimes from './assets/sometimes.png'
import pokemon from './assets/pokemon.png'

function App() {
  return (
    <div>
      <div className="grain"></div>
      <section class="landing">
        <h1 className="first-name">Ashish</h1>
        <h1 className="last-name">Mohite.</h1>
      </section>
      <section>
        <p className="big-para">I Build Things 🔨</p>
      </section>
      <section className="skills">
        <p className="big-para">
          Python, JavaScript, C++, React, Angular, Nextjs, Redux, Html, Css, Scss, Geodude, Bootstrap, Tailwind, TypeScript, Node,
          Express, Mongo, Onyx, MySQL, Django, Flask, PySpark, PyArrow, Abra, Redis, GraphQL, AWS, Docker, Jenkins, Nginx,
          SQLAlchemy, Firebase, Heroku, Wordpress, Jupyter, Vulpix, GitHub, Digital Ocean, Bitbucket, Arbok, Notion
        </p>
        <img src={pokemon} className="pokemon" alt="pokemon"></img>
      </section>
      <section className="guitar">
        <p className="big-para">I Also Play Guitar 🎸</p>
        <img src={sometimes} className="sometimes" alt="sometimes"></img>
      </section>
      <section className="big-para socials">
        <a href="https://github.com/ashish16052/" target="_blank" rel="noopener noreferrer">
          github.com/ashish16052
        </a>
        <a href="https://www.linkedin.com/in/ashish-s-mohite" target="_blank" rel="noopener noreferrer">
          linkedin.com/in/ashish-s-mohite
        </a>
        <a href="mailto:ashishmohite16052@gmail.com" target="_blank" rel="noopener noreferrer">
          ashishmohite16052@gmail.com
        </a>
      </section>
    </div>
  );
}

export default App;
